import * as React from "react"

export default function IconCloseModal({ setModalOpen }) {
  return (
    <span className="icon-close" onClick={ () => setModalOpen(false) }>
      <svg 
        width="30" 
        height="30" 
        viewBox="0 0 30 30" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
          <path 
            d="M28.8 0H1.2C.53625 0 0 .53625 0 1.2v27.6c0 .6638.53625 1.2 1.2 1.2h27.6c.6638 0 1.2-.5362 1.2-1.2V1.2c0-.66375-.5362-1.2-1.2-1.2zm-7.6463 20.4713c.1651.195.0263.4912-.2287.4912h-2.2088c-.1762 0-.3449-.0787-.4612-.2137L15 16.8675l-3.255 3.8813c-.1125.135-.2813.2137-.4612.2137H9.075c-.255 0-.39375-.2962-.22875-.4912L13.4325 15 8.84625 9.52875c-.03696-.04346-.06067-.09662-.06832-.15316-.00765-.05654.00109-.11409.02517-.16582.02408-.05172.06251-.09545.1107-.12598.0482-.03054.10415-.0466.1612-.04629h2.2088c.1762 0 .345.07875.4612.21375L15 13.1325l3.255-3.88125c.1125-.135.2812-.21375.4612-.21375h2.2088c.255 0 .3938.29625.2287.49125L16.5675 15l4.5862 5.4713z" 
            fill="white"/>
      </svg>
    </span>
  )
}