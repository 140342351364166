import * as React from "react"
import { Link } from "gatsby"
import ReactMarkdown from 'react-markdown';

import IconCloseModal from "./icons/IconCloseModal"

const ModalWindow = ({ setModalOpen, headline, content, ctas }) => (
  <div className="modal-window__container">
    <div className="modal-window">
      <div className="container">

        <div className="row">
          <div className="col-md-11">
            <div className="modal-window__intro">
              <p>Requirements to become a</p>
              <IconCloseModal setModalOpen={setModalOpen} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-11">
            <ReactMarkdown className="modal-window__headline">{ headline }</ReactMarkdown>
          </div>
        </div>

        <div className="row">
          <div className="col-md-10">
            <ReactMarkdown className="modal-window__content">{ content }</ReactMarkdown>
            <div className="modal-window__ctas">
              {
                ctas?.map( cta => <Link key={cta.button_label} className="button" to={cta.button_url}>{cta.button_label}</Link>)
              }
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
)

export default ModalWindow