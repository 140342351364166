import React from 'react';
import showdown from "showdown";
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';

export default function BecomeCouncillorHero({ children, headline, subhead, intro, ctas, video, justify, size, page}) {
  const converter = new showdown.Converter();
  const htmlIntro = intro;

  const displayCtas = ( ctas ) => (
    <div className="hero__ctas">
      <div className={ `row ${ justify === 'center' ? 'justify-content-center text-center' : '' }`}>
        
        {
          ctas.map(cta => (
            <div key={ cta.button_label } className="col-md">
              <Link class="button button--100" to={cta.button_url}>
                { cta.button_label }
              </Link>
            </div>
          ))
        }

      </div>
    </div>
  )

  return (
    <div className={`hero hero--${size} hero--${page ? page : ''}`} >
      {
        video
          ? <div className="hero__background">
              <video autoPlay loop muted playsInline>
                <source src={ video } type="video/mp4" />
              </video>
            </div>
          : ''
      }
      { 
        children.length ? children[0] : children
      }
      <div className={ `container d-flex flex-column ${ justify === 'center' ? 'justify-content-between' : '' }`}>
        

        <div className={ `row ${ justify === 'center' ? 'justify-content-center text-center' : '' }`}>
          <div className={`hero__headline-wrapper ${justify === 'center' ? 'col-md-8' : 'col-md-6'}`}>
            <h1 className="hero__headline">{ headline }</h1>
            {
              subhead ? <h2 className="hero__subtitle">{ subhead }</h2> : ''
            }
          </div>
          {
            children[1] ? children[1] : ''
          }
        </div>


        <div className={ `row ${ justify === 'center' ? 'justify-content-center text-center' : '' }`}>
          <div className={ `hero__intro-wrapper ${justify === 'center' ? 'col-md-8' : 'col-md-6'}` }>
            
       
              <ReactMarkdown className="hero__intro">{ htmlIntro }</ReactMarkdown>
              
              {
                ctas ? displayCtas( ctas ) : ''
              }

          </div>
        </div>
        
        
      </div>
      <div className="images-mobile">
          {
            children[2] ? children[2] : ''
          }
          {
            children[3] ? children[3] : ''
          }
        </div>

    </div>
  )
}